@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .marker-icon {
  background-position: center;
  background-size: 22px 22px;
  border-radius: 50%;
  height: 22px;
  left: 4px;
  position: absolute;
  text-align: center;
  top: 3px;
  transform: rotate(45deg);
  width: 22px;

} */
/* .marker {
  height: 30px;
  width: 30px;
} */
/* .marker-content {
  background: #9b59b6 !important;
  border-radius: 50% 50% 50% 0;

  height: 30px;
  left: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
 
  width: 30px;
} */

/* .marker-content {
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 50% 50% 50% 0;
  height: 30px;

  left: 50%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 50%;
 
  width: 30px;

} */
.marker-content::before {
  /* background: #ffffff;
  border-radius: 50%;
  content: '';
  height: 24px;
  margin: 3px 0 0 3px;
  position: absolute;
 
  width: 24px;
  z-index: -1; */
}

/* .mapboxgl-popup-tip {
  display: none !important;
} */

/* .mapboxgl-popup-content {
  background-color: none !important;
  background: none !important;
  border: 3px solid white;
  border: none !important;
  box-shadow: none !important;
  border-radius: 50%;
  padding: 0;
  margin: 0;

 
} */

.custom-marker-cluster {
  width: 33px; /* Match iconSize */
  height: 33px; /* Match iconSize */
  /* background-color: darkorchid; */
  /* background-color: #1976d2; */
  background-color: rgba(25, 118, 210, 0.9); /* Adjusted to be more translucent */
  border-radius: 50%;

  color: aliceblue;
  position: relative;
  font-size: 18px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}
/* .custom-marker-cluster::before {
  content: '';
  position: absolute;
  top: -0;
  left: -0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  z-index: -1;
} */
.custom-marker-cluster span {
  display: inline-block;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

/* Custom styles to remove the white background from the popup */
.leaflet-popup-content-wrapper {
  background: none !important;
  box-shadow: none !important;
}

.leaflet-popup-tip-container {
  display: none !important;
}

/* Custom styles to hide the popup close button */
.leaflet-popup-close-button {
  display: none !important;
}

/* .pulsating-circle {
  width: 100px;
  height: 100px;
  background-color: rgba(150, 0, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 20px 10px rgba(150, 0, 255, 0.7);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 20px 10px rgba(150, 0, 255, 0.7);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 30px 15px rgba(150, 0, 255, 0.5);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 20px 10px rgba(150, 0, 255, 0.7);
  }
} */
/* Add this to your CSS or styled components */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideInFromBottom 3s ease-out;
}

/* General approach for all major browsers */
body {
  overflow: auto; /* Ensure scroll functionality */
}

/* Hide scrollbar for WebKit browsers */
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
